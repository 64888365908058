import { createAction, props } from '@ngrx/store';

export const actionPrefix = '[routerCustom]';

export const setInitUrl = createAction(actionPrefix + ' SetInitUrl', props<{ url: string }>());

export const pushDetailViewUrl = createAction(actionPrefix + ' Push detail view url', props<{ url: string }>());

export const popDetailViewUrl = createAction(actionPrefix + ' Pop detail view url');

export const clearDetailViewUrls = createAction(actionPrefix + ' Clear detail view urls');
